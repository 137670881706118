
export const colorWheels = {

  "gray": {
    "50": '#efefef',
    "100": '#e3e3e3',
    "150": '#d8d8d8',
    "200": '#cccccc',
    "250": '#c1c1c1',
    "300": '#b6b6b6',
    "350": '#ababab',
    "400": '#a0a0a0',
    "450": '#969696',
    "500": '#8b8b8b',
    "550": '#818181',
    "600": '#777777',
    "650": '#6c6c6c',
    "700": '#626262',
    "750": '#595959',
    "800": '#4f4f4f',
    "850": '#464646',
    "900": '#3d3d3d',
    "950": '#343434',
    "1000": '#2b2b2b',
  },
  "yellow": {
    "50": "#FEF9E7",
    "100": "#FBEDBC",
    "200": "#F9E290",
    "300": "#F6D665",
    "400": "#F3CB39",
    "500": "#F1BF0E",
    "600": "#C1990B",
    "700": "#917308",
    "800": "#604C06",
    "900": "#302603"
  },
  "orange": {
    "50": "#FFF3E5",
    "100": "#FFDDB8",
    "200": "#FFC78A",
    "300": "#FFB25C",
    "400": "#FF9C2E",
    "500": "#FF8600",
    "600": "#CC6B00",
    "700": "#995000",
    "800": "#663600",
    "900": "#331B00"
  },
  "red": {
    50: '#feeef2',
    100:'#fcdee4',
    150:'#fbcdd7',
    200:'#fabcca',
    250:'#f9acbd',
    300:'#f79baf',
    350:'#f68aa2',
    400:'#f57995',
    450:'#f36987',
    500:'#f2587a',//on-brand
    550:'#da4f6e',
    600:'#c24662',
    650:'#a93e55',
    700:'#913549',
    750:'#792c3d',
    800:'#612331',
    850:'#491a25',
    900:'#301218',
    950:'#18090c',
  },
  "purple": {
    50:'#f5dcf5',
    100:'#f0cbf0',
    150:'#ebb9eb',
    200:'#e6a7e6',
    250:'#e195e1',
    300:'#dc82dc',
    350:'#d66ed6',
    400:'#d059d0',//on-brand
    450:'#be50be',
    500:'#ac49ac',
    550:'#9a419a',
    600:'#893a89',
    650:'#783378',
    700:'#672c67',
    750:'#572557',
    800:'#471e47',
    850:'#381838',
    900:'#2a122a',
    950:'#1a0b1a',
  },
  "blue": {
    10: '#e0d4f6',
    50: '#d4c7f0',
    100: '#c9bbe9',
    150: '#beaee3',
    200: '#b3a1dd',
    250: '#a895d6',
    300: '#9d89d0',
    350: '#927cca',
    400: '#8770c4',
    450: '#7d64bd',
    500: '#7258b7',
    550: '#674cb1',
    600: '#5c3fab',
    650: '#5133a4',//on-brand
    700: '#462996',
    750: '#3c2283',//on-brand
    800: '#321c6f',
    850: '#29165b',
    900: '#201048',//feature-button
    950: '#160b32',//on-brand
    999: "#090a18",//on-brand
  },
  "aqua": {
    "50": "#E6F4FF",
    "100": "#B8E1FF",
    "200": "#8ACDFE",
    "300": "#5DBAFE",
    "400": "#2FA6FE",
    "500": "#0193FE",
    "600": "#0175CB",
    "700": "#015898",
    "800": "#003B66",
    "900": "#001D33"
  },
  "cyan": {
    "50": "#E5FBFF",
    "100": "#B8F3FF",
    "200": "#8AECFF",
    "300": "#5CE4FF",
    "400": "#2EDDFF",
    "500": "#00D5FF",
    "600": "#00AACC",
    "700": "#008099",
    "800": "#005566",
    "900": "#002B33"
  },

  "teal": {
    "50": "#ECF8F9",
    "100": "#C9ECEE",
    "200": "#A6E0E2",
    "300": "#84D4D7",
    "400": "#61C8CC",
    "500": "#3EBBC1",
    "600": "#32969A",
    "700": "#257074",
    "800": "#194B4D",
    "900": "#0C2527"
  },
  "green": {
    "50": "#F4F9EB",
    "100": "#DFEFC8",
    "200": "#CBE4A4",
    "300": "#B7DA81",
    "400": "#A2CF5E",
    "500": "#8EC53A",
    "600": "#719D2F",
    "700": "#557623",
    "800": "#394F17",
    "900": "#1C270C"
  },

}


import React from "react";
import { Link, Text} from "@chakra-ui/react";

import {S, VFlex, VFlexCC, VFlexCS} from './bits/UtilityTags.js';
import {H1, H2, H3} from "./bits/H";
import AppFooter from "./navs/AppFooter";

export default function PgAbout({...props}) {
  return (
    <VFlexCS id='home'>

      <VFlex mt={40} w={['97%', '92%', '580px']} gap={3}>
        <H1 sx={{alignSelf: 'center',
          mb: '20px'}}>About <S color={'brand.purple'}>Cubecert</S></H1>
        <H3>Welcome to Cubecert, LLC</H3>

        <Text>
          At Cubecert, LLC, we are dedicated to shaping the future through cutting-edge technology and innovative solution creation. Our mission is to revolutionize industries and enhance everyday life with forward-thinking solutions that protect consumers and provide trusted information at their fingertips.
        </Text>
        <H3>Who We Are</H3>
        <Text>
          Cubecert, LLC was founded by a team of passionate visionaries and seasoned experts with a shared commitment to pushing the boundaries of technology.
        </Text>
        <Text>
          Our diverse team combines expertise in engineering, design, and business strategy to deliver groundbreaking solutions that set new standards in the tech world.
        </Text>
        <H3>What We Do</H3>
        <Text>
          From concept to creation, we created a state-of-the-art product that solves real-world problems. Our product will have a profound impact on a wide range of
          industries, including luxury goods, healthcare, automotive and consumer electronics, among many others.
        </Text>
        <H3>Our Approach</H3>
        <Text>
          Innovation is at the heart of everything we do. Our approach is defined by:
        </Text>
        <Text>
          <strong>Collaborative Design:</strong> We work closely with our clients, valuing their insights and feedback to
            solve complex problems and create solutions that exceed expectations.
        </Text>
        <Text>
          <strong>Agile Methodology:</strong> Our agile development process allows us to stay flexible and responsive,
            delivering high-quality solutions.
        </Text>
        <Text>
          <strong>Continuous Improvement:</strong> Our commitment to excellence doesn't end with
          deployment. We encourage regular feedback with our customers and implement updates and enhancements
          based on feedback and technological advancements.

        </Text>
        <H3>Join Us on Our Journey</H3>
        <Text>
          At Cubecert, LLC, we believe that the future is built today. We invite you to join us on our journey to embrace this technology that will transform industry.
        </Text>
        <Text>
          Whether you are a potential client, a future team member, or a collaborator, we look forward to building a brighter, more innovative future together.
        </Text>
      </VFlex>
      <VFlex mt={40} w={['97%', '92%', '89%', '940px']} gap={3}>
        <AppFooter />
      </VFlex>



    </VFlexCS>
  )
}

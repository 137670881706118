import React, {useEffect, useState, useCallback} from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  Text,
  Textarea,
  chakra,
  FormControl,
  FormHelperText, FormErrorMessage, Link
} from '@chakra-ui/react';
import {HFlex, HFlexCC, HFlexSC, VFlex, VFlexCC, VFlexCS} from "./UtilityTags";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import WBIcon from "./WBIcon";
import Circle1 from "assets/img/Circle 01.png";
import Circle2 from "assets/img/Circle 02.png";
import BrandLogoBlack from "assets/logos/logo-black.svg";
import Mtn from "./Mtn";
import {captchaSitekey, serverOrigin} from "../../data/constants";
import ReCAPTCHA from "react-google-recaptcha";

// Define validation schema
const schema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.empty': `'Email' cannot be an empty field.`,
    'string.email': `'Email' must be a valid email.`,
    'any.required': `'Email' is a required field.`,
  }),
  body: Joi.string().min(5).required().messages({
    'string.empty': `'Message' cannot be an empty field.`,
    'string.min': `'Message' should have a minimum length of 5.`,
    'any.required': `'Message' is a required field.`,
  }),
});

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [submitMessage, setSubmitMessage] = useState('');
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const recaptchaRef = React.useRef();

  const { control, handleSubmit, reset, formState:{ errors, isValid, touched } } = useForm({
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const asyncScriptOnLoad = () => {
    setCaptchaLoaded(true);
    console.log("scriptLoad - reCaptcha Ref-");
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (!captchaLoaded || !recaptchaRef) {
        setIsLoading(false);
        setSubmitStatus('error');
        setSubmitMessage('ReCAPTCHA unavailable. Please try again.');
        return;
      }
      const captchaToken = recaptchaRef.current?.getValue()
      if (!captchaToken) {
        setIsLoading(false);
        setSubmitStatus('error');
        setSubmitMessage('ReCAPTCHA unsuccessful. Please try again.');
        recaptchaRef.current?.reset()
        return;
      }

      data.token = captchaToken; // Add token to form data

      const response = await fetch(`${serverOrigin}/site/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      setIsLoading(false);

      if (response.ok) {
        setSubmitStatus('success');
        setSubmitMessage('Success! We got your message, and will be in touch as soon as possible.');
        reset();
      } else {
        const responseData = await response.json();
        setSubmitStatus('error');
        setSubmitMessage(responseData.message || 'Form could not be submitted. Try again?');
      }
    } catch (err) {
      setSubmitStatus('error');
      setSubmitMessage('Form could not be submitted. Try again later.');
      setIsLoading(false);
    }
  };

  const handleRecaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    }
  };

  const handleRecaptchaExpired = () => {
    setCaptchaVerified(false);
  };

  useEffect(() => {
    // Cleanup function to remove any event listeners or timers
    return () => {
      reset();
      setSubmitMessage('');
    };
  }, [reset]);
  // console.log('errors', errors);
  // console.log('touched', touched);
  // console.log('isValid', isValid);
  // console.log('isLoading', isLoading)
  console.log('captchaSitekey', captchaSitekey)
  return (
    <VFlexCS gap={7}
             sx={{
               position: 'relative',
               bgColor: 'brand.bgDarkerGray',
               overflow: 'hidden',
               borderRadius: 20,
               px: 5,
               pb: 5,
               w: 'full'
             }}>
      <Box sx={{
        bgImage: Circle1,
        boxSize: '200px',
        bgSize: 'cover',
        bgPosition: 'center center',
        position: 'absolute',
        bottom: '-50',
        left: '-50',
        '@keyframes rotate': {
          from: { transform: 'rotate(0deg)' },
          to: { transform: 'rotate(-360deg)' },
        },
        animation: 'rotate 6s infinite linear'
      }}/>
      <Box sx={{
        bgImage: Circle2,
        boxSize: '200px',
        bgSize: 'cover',
        bgPosition: 'center center',
        position: 'absolute',
        top: '-50',
        right: '-50',
        '@keyframes rotate': {
          from: { transform: 'rotate(0deg)' },
          to: { transform: 'rotate(360deg)' },
        },
        animation: 'rotate 6s infinite linear'
      }}/>
      <Mtn.InFromAbove><VFlexCC sx={{boxSize:'200px', borderRadius:50, gap:5, position:'relative'}}>
        <Box sx={{bgColor:'#00d4ff', opacity:'0.1',width:'1px', height:'50%', position:'absolute', left:'50%', top:0,}}/>
        <Box sx={{boxSize:'170px', opacity:'0.2', bgColor:'#00d4ff', borderRadius:100,filter: "blur(20px)",position:'absolute',}}/>
        <WBIcon i={BrandLogoBlack} alt="Brand Logo Title" size={12} p={3} br={8} zIndex={1}/>
        <Heading sx={{position:'absolute', bottom:10,}} as='h4' fontWeight={500} size='md' textAlign='center'>
          Get early access
        </Heading>
      </VFlexCC></Mtn.InFromAbove>

      {/*widen inputs and decrease width of entire form box in widest bpoint*/}
      {submitStatus === 'success' ? (
        <Box textAlign='center' color='green.500'>
          {submitMessage}
        </Box>
      ) : (
        <VFlexCS gap={7} as={chakra.form} onSubmit={handleSubmit(onSubmit)} w={'335px'}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl isInvalid={errors.email}>
                <Input type='email' placeholder='Email' {...field}/>
                {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
              </FormControl>
            )}
          />

          <Controller
            name="body"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl isInvalid={errors.body}>
                <Textarea placeholder='Please tell us about yourself' {...field} />
                {errors.body && <FormErrorMessage>{errors.body.message}</FormErrorMessage>}
              </FormControl>
            )}
          />

          <ReCAPTCHA
            id='captchabox'
            style={{ display: "inline-block" }}
            ref={recaptchaRef}
            // size='compact'
            theme='dark'
            sitekey={captchaSitekey}
            asyncScriptOnLoad={asyncScriptOnLoad}
            onChange={handleRecaptchaChange}
            onExpired={handleRecaptchaExpired}
          />
          <Button variant='purple' type='submit' isDisabled={!isValid || !captchaVerified} isLoading={isLoading}>
            {submitStatus === 'error' ? 'Try again' : 'Submit'}
          </Button>
{/*
          <Text sx={{fontSize:10, wordBreak:'break-word', textAlign:'center'}} >
            This site is protected by reCAPTCHA and the Google&nbsp;
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>&nbsp;and&nbsp;
            <Link href="https://policies.google.com/terms">Terms of Service</Link>&nbsp;apply.
          </Text>
*/}
          {submitStatus === 'error' && <Text color='red.500' dangerouslySetInnerHTML={{ __html: submitMessage }} />}
        </VFlexCS>
      )}

      <Mtn.FadeScaleIn style={{ display: 'flex', justifyContent: 'center' }}>
        <Text textAlign='center' alignSelf={'center'} mt={6} w={['79%', '65%', '65%', '78%']}>
          We look forward to connecting with you.
        </Text>
      </Mtn.FadeScaleIn>
    </VFlexCS>
  );
};
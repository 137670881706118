import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useCleanupLastPass = () => {
  const location = useLocation();

  useEffect(() => {
    const removeLastPassElements = () => {
      const lastPassRoot = document.querySelector('[data-lastpass-root]');
      if (lastPassRoot && lastPassRoot.parentElement) {
        lastPassRoot.parentElement.removeChild(lastPassRoot);
      }
    };

    if(location.pathname !=='/') removeLastPassElements();
  }, [location]);
};

export default useCleanupLastPass;

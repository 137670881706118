import { create } from 'zustand';
import {produce} from 'immer';

export const useAppStore = create((set,get) => ({
  appMainRef: null,
  setAppMainRef: (newRef) => set(() => ({ appMainRef: newRef })),

  appNavDrawerOpen:false,
  set_appNavDrawerOpen: async (bOpen) => {
    set({appNavDrawerOpen:bOpen});
  },

  apiKeyModalIsOpen:false,
  set_apiKeyModalIsOpen: (bOpen) => {
    set({apiKeyModalIsOpen:bOpen});
  },

  closeStripeIntentModal: () => {
    set({stripeIntentModalClientSecret:''});
    set({stripeIntentModalIsOpen:false});
    // set({stripeIntentModalStatusMessage:''})
  },
  set_stripeIntentModalIsOpen: (bOpen, type) => {
    set({stripeIntentModalIsOpen:bOpen});
  },
  set_isWindowScrolled: (bIsScrolled) =>set({isWindowScrolled:bIsScrolled}),
  isWindowScrolled:false,
  set_isAppMainScrolled: (bIsScrolled) => set({isAppMainScrolled:bIsScrolled}),
  isAppMainScrolled:false,

  dashTabIdx:0,
  loginPageInitTab:'login', //login|signup
  authModalIsOpen:false,
  authModalTabIndex: 0,
  set_authModalIsOpen: async (bOpen, initialTab) => {
    if(initialTab){
      set({
        authModalTabIndex: {
          'login':0,
          'signup':1,
        }[initialTab]
      })
    }
    set({authModalIsOpen:bOpen});
  },

  _s: (fn) => set(produce(fn)),
}))

export const appState = ()=>useAppStore.getState();

// src/components/NavLink.js
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { useAppStore } from '../services/useAppStore';

const NavLink = ({ to, elementId, children, ...props }) => {
  const navigate = useNavigate();
  const appMainRef = useAppStore(state => state.appMainRef);

  const handleClick = (e) => {
    e.preventDefault();
    navigate(to);
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element && appMainRef.current) {
        appMainRef.current.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
      }
    }, 100); // Small delay to ensure the navigation has completed
  };

  return (
    <Link as={RouterLink} to={to} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

export default NavLink;
import React from "react";
import {VFlex, VFlexSS} from "./UtilityTags";
import {Box, Grid, Heading, Text} from "@chakra-ui/react";
import Mtn from "./Mtn";
import BlueBlurBg from "../../assets/img/Blue Blur.webp";
import OrangeBlurBg from "../../assets/img/Orange Blur.jpg";
import Product1 from "../../assets/products/PurseBlue-opt.png";
import Product2 from "../../assets/products/Wheel2-opt.png";
import Product3 from "../../assets/products/art-horse-opt.png";
import Product4 from "../../assets/products/Pill-Bottle-opt.png";

const ProductCard = ({bgImg, img, title, text, ...rest}) => {
  return (
    <VFlex
      w="200px"
      h='300px'
      gap={2}
      {...rest}
    >
      <Box sx={{
             position:"relative",
             width: '100%',
             height: '170px',
             overflow:"hidden",
             borderRadius:"20px",
        backgroundColor: '#0a0a0a',
             _hover:{ ".bgImage": { opacity: 1 } }
           }}>
        <Box
          className="bgImage"
          sx={{
            backgroundImage: bgImg,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            width: '200%',
            height: '200%',
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%) rotate(0deg)",
            opacity: 0,
            transition:'opacity 0.7s',
            animation: 'rotation 7s infinite linear',
            '@keyframes rotation': {
              'from': {
                transform: 'translate(-50%, -50%) rotate(0deg)',
              },
              'to': {
                transform: 'translate(-50%, -50%) rotate(359deg)',
              }
            }
          }}
        />
        <Mtn.InFromRight
          isChild
          className='productImg'
          transition={{ type: "tween", ease: "easeInOut" }}
          sx={{
            backgroundImage: img,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            position:'absolute',
            width: '100%',
            height: '100%',
          }}/>
      </Box>
      <Heading size='sm'>{title}</Heading>
      <Mtn.InFromBelow><Text>{text}</Text></Mtn.InFromBelow>
    </VFlex>
  )
};

const ProductCards = ({bgImg, img, title, text, ...rest}) => {
  return (
    <Mtn.Box
      sx={{
        display:'grid',
        gridTemplateColumns:["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"],
        // w:"100%",
        gap:'40px',
        alignItems: "center",
        justifyItems: "center",

      }}
      id='product-cards'
      variants={{
        hidden: {},
        show: {
          transition: {
            duration: .4,
            delayChildren: .1,
            staggerChildren: .1,
            // staggerDirection: 1
          }
        }
      }}
      initial="hidden"
      whileInView='show'
      viewport={{once: false}}
    >
      <ProductCard bgImg={BlueBlurBg} img={Product1}
                                title='Clothing & Apparel'
                                text='Enable consumers to independently authenticate high end products.'/>
      <ProductCard bgImg={OrangeBlurBg} img={Product2}
                                title='Automotive'
                                text='Drive consumer confidence that custom automotive accessories are not competitor fakes.'/>
      <ProductCard bgImg={BlueBlurBg} img={Product3}
                                title='Art'
                                text='One-of-a-kind art tagged with your personal digital certification.'/>
      <ProductCard bgImg={OrangeBlurBg} img={Product4}
                                title='Medical & Health'
                                text='Ensure consumers know that your prescriptions are authentic and made in the U.S.A.'/>
    </Mtn.Box>
  )
};

export default ProductCards;
import React from 'react';
import { Grid, Heading,Box } from '@chakra-ui/react';


export default function PgDocs() {

  return (
    <Box sx={{pt:'70px'}}>
      <Heading>Docs</Heading>
    </Box>
  );
}

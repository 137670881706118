import React, { useState, useEffect } from 'react';
import { Box, Badge, Text, chakra } from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";

export const BreakpointsBadge = ({ ...rest }) => {
  const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
  const activeBreakpoint = useBreakpointValue({ base: 'xs', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const breakpointsComponents = breakpoints.map((breakpoint, idx) =>
    activeBreakpoint === breakpoint
      ? <Badge key={idx} colorScheme='gray' m={1} textTransform="none">{breakpoint}</Badge>
      : <chakra.span key={idx} color='rgba(255, 255, 255, 0.2)' m={1}>{breakpoint}</chakra.span>
  ).reduce((prev, curr, i) => [prev, <chakra.span key={`comma-${i}`}>, </chakra.span>, curr]);

  return (
    <Box {...rest} bgColor={'rgba(0,0,0, 0.6)'} px={1} borderRadius={4}>
      <chakra.span style={{fontSize:11, color:'#aaa'}}>[{breakpointsComponents}] {width}px</chakra.span>
    </Box>
  );
};

export default BreakpointsBadge;
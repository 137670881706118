import React from "react";
import { createRoot } from 'react-dom/client';
import {ColorModeScript, ChakraProvider, Box} from '@chakra-ui/react'
import theme from './theme/theme.js';
import App from './App.js';
import {useAppStore} from "./services/useAppStore";
import ReactGA from 'react-ga4';
import {isBrowserUnsupported, UnsupportedBrowserMessage} from "./views/bits/UnsupportedBrowserMessage";
const MEASUREMENT_ID = "G-FT21C4WY06"; // Replace with your Measurement ID
ReactGA.initialize(MEASUREMENT_ID);

// Get the root element
const rootElement = document.getElementById("root");

// Get Zustand set function
const setAppMainRef = useAppStore.getState().setAppMainRef;

// Create a ref and set it to the store
const rootRef = { current: rootElement };
setAppMainRef(rootRef);

const root = createRoot(rootElement);

root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
    <ChakraProvider theme={theme} resetCss={true} >
      {/*<UnsupportedBrowserMessage />*/}
      {isBrowserUnsupported() ? <UnsupportedBrowserMessage /> : <App />}
      {/*<App />*/}
    </ChakraProvider>
  </>
);


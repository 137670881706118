/*eslint-disable*/
import React, {useCallback, useEffect} from "react";
import {Box, chakra, Divider, Flex, Heading, HStack, Image, Link, List, ListItem, Text} from "@chakra-ui/react";
import PropTypes from "prop-types";
import {useLocation, useNavigate} from 'react-router-dom';
import NavLink from 'components/NavLink';
import {
  HFlex,
  HFlexCC,
  HFlexCS,
  HFlexSC,
  HFlexSS,
  VFlex,
  VFlexCC,
  VFlexCS,
  VFlexSC,
  VFlexSS
} from "../bits/UtilityTags";
import BrandLogo from "assets/logos/logo-white.svg";
import BrandLogoTitle from "assets/logos/logo-title-hstack-white.svg";
import { FaXTwitter, FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa6";
import {outfit, plex} from "../../theme/foundations/fonts";
import {FaExternalLinkAlt} from "react-icons/fa";
import {useAppStore} from "../../services/useAppStore";

const SocMediaCombo = ({text, I, href})=>(
  <HFlexCC gap={3} as={Link} isExternal href={href} cursor='pointer'>
    <I color="white" />
    <Text              sx={{_hover:{color:'white'}}}
    >{text}</Text>
  </HFlexCC>
)

export default function AppFooter(props) {
  const nav = useNavigate()
  const { appMainRef } = useAppStore();
  const location = useLocation();
  const currentPath = location.pathname;
  const scrollToTop = useCallback(() => appMainRef.current?.scrollTo(0, 0), [appMainRef]);


  // const linkTeal = "red.200"=
  return (
    <VFlexCS id='AppFooter'
             justifyContent="space-between"
             backgroundColor='black'
             px="1%"
             w={'100%'}
             // minHeight="280px"
             gap={5}
             mt={20}
    >
      <HFlex justify={['center']} flexDirection={{ base: "column", sm: "row" }} w={['80%','100%']} gap={12}>
        <VFlex>
          <Image  src={BrandLogoTitle} h={['40px','40px','50px']}/>
          <Text w={['280px']}>An innovative, patented, decentralized, product authentication solution.</Text>
          <Text>US Patent No. US11991284B1</Text>

        </VFlex>
        <HFlexCS gap={10} mt={[0,2]} flexDirection={{ base: "column", sm: "row" }}>
          <VFlex gap={2}>
            <Heading size='sm'>COMPANY</Heading>
            <NavLink to='/terms-of-use' elementId='home' variant='gray'>Terms Of Use</NavLink>
            <NavLink to='/privacy' elementId='home' variant='gray'>Privacy Policy</NavLink>
            <NavLink to='/about' elementId='home' variant='gray'>About</NavLink>
            <NavLink to='/' elementId='contact' variant='gray'>Contact</NavLink>
            <Link whiteSpace={'nowrap'} variant='gray' href="/cubecert_whitepaper.pdf" isExternal display={["block", "block", "none"]}>
              <Flex alignItems="center" gap={1}>
                <Text as="span" whiteSpace="nowrap">Whitepaper</Text>
                <FaExternalLinkAlt/>
              </Flex>
            </Link>

          </VFlex>
          <VFlex gap={2}>
            <Heading size='sm'>SOCIAL MEDIA</Heading>
            <SocMediaCombo text='Instagram' I={FaInstagram} href='https://www.instagram.com/cubecert'/>
            <SocMediaCombo text='Facebook' I={FaFacebook} href='https://www.facebook.com/profile.php?id=61557233457297'/>
            <SocMediaCombo text='LinkedIn' I={FaLinkedin} href='https://www.linkedin.com/in/cubecert-cubecert-8525972b9/'/>
            <SocMediaCombo text='X' I={FaXTwitter} href='https://twitter.com/Cubecert '/>
          </VFlex>
        </HFlexCS>
      </HFlex>
      <Divider/>
      <HFlexSC justifyContent={'space-between'} w={'100%'} mb={"18px"}>
            <Text color="gray.400">
              &copy; {1900 + new Date().getYear()},{" "}
              {/* <Link */}
              {/*   as={NavLink} */}
              {/*   color="bog.600" */}
              {/*   to="/" */}
              {/*   target="_parent" */}
              {/* > */}
              {"Cubecert"}
              {/* </Link> */}
            </Text>

        <NavLink to={currentPath} elementId='home'>
          <Text>Back to top</Text>
        </NavLink>

          {/*<ListItem me={{ base: "20px", md: "44px", }}>*/}
          {/*  <Link to="/support" as={NavLink}*/}
          {/*        color="gray.400"*/}
          {/*        target="_parent"*/}
          {/*  >*/}
          {/*    {"Support"}*/}
          {/*  </Link>*/}
          {/*</ListItem>*/}
        </HFlexSC>

    </VFlexCS>
  );
}

import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const custom = defineStyle({
  variants: {
    "body": {
      fontFamily: 'plex',
      fontWeight:'400',
      fontSize:'15px',
      color: 'brand.textGray',
      // transition:'all 0.1s ease-out',
    },
  },
  baseStyle: {
    fontFamily: 'plex',
    fontWeight:'400',
    fontSize:'16px',
    color: 'brand.textGray',
    // let's also provide dark mode alternatives
  },
})

export const textStyles = {
  components: {
    Text: custom,
  },
}
import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const custom = defineStyle({
  baseStyle: {
    fontFamily: 'outfit',
    fontWeight: '500',
    fontSize: '62px',
    // let's also provide dark mode alternatives
  },
  _dark: {
    color: 'green.100',
  },
})

export const headingStyles = {
  components: {
    Heading: custom,
  },
}
//CcSite/src/views/navs/AppNav.js
import {
  Avatar, Badge, Box, Button, Center, Flex,
  Grid, GridItem, HStack, IconButton, Image, Link,
  Menu, MenuButton, MenuDivider, MenuItem, MenuList,
  Portal, Spacer, useBreakpointValue, chakra,
} from '@chakra-ui/react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {HFlex, VFlexCC} from '../bits/UtilityTags.js';
import {Outlet, useLocation, useNavigate,} from 'react-router-dom';
import {FaUserCircle} from 'react-icons/fa';
import BrandLogoTitle from "assets/logos/logo-title-hstack-white.svg";
import BrandLogo from "assets/logos/logo-white.svg";
// import { CISVG_FrogeNavBack } from '../../assets/Brand.js';
import {appState, useAppStore} from '../../services/useAppStore.js';
import {ExternalLinkIcon, HamburgerIcon} from '@chakra-ui/icons';
import {createBrowserHistory} from "history";
import { plex, outfit } from '../../theme/foundations/fonts.js';
import BreakpointsBadge from "../bits/BreakpointsBadge";
import { FaExternalLinkAlt } from "react-icons/fa";
import useCleanupLastPass from "../../hooks/useCleanupLastPass";
import ReactGA from "react-ga4";
import NavLink from 'components/NavLink';

const history = createBrowserHistory();

export default function AppNav(props) {
  const { appMainRef } = useAppStore();

  const location = useLocation();
  const [activeSection, setActiveSection] = useState(null);
  const sectionsRef = useRef([]);
  useCleanupLastPass();  // Use the custom hook
  const scrollToTop = useCallback(() => appMainRef.current?.scrollTo(0, 0), [appMainRef]);

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
  // console.log('activeSection', activeSection)
  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) setActiveSection(entry.target.id);
      });
    };
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '-50% 0px -50% 0px',
      threshold: 0
    });


    const sections = document.querySelectorAll('[id^="section"]');
    sectionsRef.current = sections;

    if(location.pathname === "/") {
    sections.forEach((section) => observer.observe(section));
    } else {
      setActiveSection(null);
      sections.forEach((section) => observer.unobserve(section));
    }

    return () => {
      sectionsRef.current.forEach((section) => observer.unobserve(section));
    };
  }, [location.pathname]);

  // console.log(`loc: `, location);

  const isWindowScrolled = useAppStore(s=>s.isWindowScrolled)
  const isMobile = useBreakpointValue({base:true, md:false})
  const toggleAppNavDrawer=()=>appState().set_appNavDrawerOpen(!appState().appNavDrawerOpen)

  const navigate = useNavigate();

  const navigateTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const sxFixedBar = {zIndex:'1000',position:"fixed", w:'calc(100vw - 6px)', h:'60px', top:"0", left:"0", right:"0",}

  const sxPageNavs = {...plex.md.md, color:'white', textShadow: '0px 1px 4px rgba(0, 0, 0, 0.4)'}

  const sxDesktopNavBase = {
    bg: 'bog.700', minH: '80px', py: { base: 2 }, px: { base: 4 },
    borderBottom: '1', borderStyle: 'solid', borderColor: 'bog.900',
    justifyContent: 'end', alignItems: 'center',
  }

  const sxDNavLink = { display:{base:'none',md:'block'},
    fontSize:'sm', fontWeight:'500', color:'gray.200',mx:'.8rem', position:'relative',
    _hover:{ textDecoration: 'none', color: 'white', },textAlign: 'center'
  }
  const navsInfo = [
    {
      text: 'Features',
      smoothTo: 'features',
      breadth: ['features','section3','section4','section5','section6']
    },
    // {
    //   text: 'Company',
    //   smoothTo: 'section5',
    //   breadth: ['section5','section6','section7']
    // },
    {
      text: 'Plans',
      smoothTo: 'plans',
      breadth: ['plans', 'section9']
    },
    {
      text: 'Contact',
      smoothTo: 'contact',
      breadth: ['contact', 'section11']
    },
  ]
  return (<>
      <Portal>
        <Box sx={{...sxFixedBar,
          backdropFilter: 'blur(20px)', borderBottom: '1px solid rgba(255,255,255,.2)'}}
        />
        {/*<BreakpointsBadge sx={{position: 'fixed', bottom: '0', left: '0'}}/>*/}
        <Grid
          id="__AppNavbar"
          sx={{
            ...sxFixedBar,
            px: '12px',
            alignItems: 'center',
          }}
          templateColumns="1fr auto 1fr"
        >
          <NavLink to={'/'} elementId={'home'} ml="5px">
            <Flex
              sx={{
                bgImage:BrandLogoTitle,
                bgSize:'contain',
                bgRepeat:'no-repeat',
                bgPosition:'unset',
                h: {base:'27px', lg:'30px'},
                mt: '1px',
                cursor: 'pointer',
              }}

            />
          </NavLink>

          <HStack spacing={5} display={{ base: 'none', md: 'flex' }} h='100%' justify="center">
            {
              navsInfo.map(({ text, smoothTo, breadth }) => (
                <Box
                  key={smoothTo}
                  as={NavLink}
                  to={`/`}
                  elementId={smoothTo}
                  sx={{
                    ...sxPageNavs,
                    position: 'relative',
                    h:'100%',
                    display: 'flex',
                    fontSize:'18px',
                    alignItems: 'center',
                    '::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      width: '100%',
                      height: '3px',
                      backgroundColor: 'white',
                      opacity: breadth.includes(activeSection) ? 1 : 0,
                      transition: 'opacity 0.4s ease-in-out',
                      filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2))'
                    },
                  }}
                >
                  {text}
                </Box>
              ))
            }
            <chakra.a
              href={"/cubecert_whitepaper.pdf"}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                ...sxPageNavs,
                position: 'relative',
                h:'100%',
                display: 'flex', // Added
                alignItems: 'center', // Added
                fontSize:'18px',
              }}
            >
              Whitepaper&nbsp;
              <FaExternalLinkAlt style={{filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5))'}}/>
            </chakra.a>
            <Box
              as={NavLink}
              to={`/about`}
              elementId={'home'}
              sx={{
                ...sxPageNavs,
                position: 'relative',
                h:'100%',
                display: 'flex',
                alignItems: 'center',
                fontSize:'18px',
                '::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  width: '100%',
                  height: '3px',
                  backgroundColor: 'white',
                  opacity: location.pathname === '/about' ? 1 : 0, // Change this line
                  transition: 'opacity 0.4s ease-in-out',
                },
              }}

            >
              Company
            </Box>
          </HStack>

{/*
          <Box display={{ base: 'none', md: 'block' }} textAlign="right">
            <Button variant={'purple'} h={8}>Login</Button>
          </Box>
*/}
        </Grid>
      </Portal>

      <Outlet/>

    </>
  );
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {width,height};
}

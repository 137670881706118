export const linkStyles = {
  components: {
    Link: {
      variants: {
        "gray": {
          fontFamily: 'plex',
          fontWeight:'400',
          fontSize:'16px',
          color: 'brand.textGray',
          // transition:'all 0.1s ease-out',
          _hover: {
            textDecoration: "none",
            color:'white'
          },

        },
      },
      decoration: "none",
      baseStyle: {
        color:'white',
        _hover: {
          textDecoration: "none",
          color:'gray.600'
        },
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};

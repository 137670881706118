export function create4ColorGradientSvgBg(colors) {
  const svgTemplate = `
  <svg preserveAspectRatio='none' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'>
    <defs>
      <linearGradient id='g'>
        <stop offset='0' stop-color='#fff' stop-opacity='0'/>
        <stop offset='1' stop-color='#fff' stop-opacity='1'/>
      </linearGradient>
      <mask id='m'>
        <rect x='0' y='0' width='1' height='1' fill='url(#g)'/>
      </mask>
      <linearGradient id='a' gradientTransform='rotate(90)'>
        <stop offset='0' stop-color='${colors.tr}'/>
        <stop offset='1' stop-color='${colors.br}'/>
      </linearGradient>
      <linearGradient id='b' gradientTransform='rotate(90)'>
        <stop offset='0' stop-color='${colors.bl}'/>
        <stop offset='1' stop-color='${colors.tl}'/>
      </linearGradient>
    </defs>

    <rect x='0' y='0' width='1' height='1' fill='url(#a)' mask='url(#m)'/>
    <rect x='0' y='0' width='1' height='1' fill='url(#b)' mask='url(#m)' transform='translate(1,1) rotate(180)'/> 
  </svg>`;

  // Clean up and encode the SVG
  let r = svgTemplate
    .replace(/> +/g, ">")
    .replace(/ +</g, "<")
    .replace(/>/g, "%3E")
    .replace(/</g, "%3C")
    .replace(/#/g, "%23")
    .replace(/"/g, "'")
    .replace(/ +/g, " ")
    .replace(/^ +/g, "")
    .split("\n")
    .join(" ");

  // Return the background-image css rule
  return `url("data:image/svg+xml;utf8,${r}");`;
}

export function create4ColorGradientSvgBg_modTRtoR(colors) {
  const svgTemplate = `
  <svg preserveAspectRatio='none' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'>
    <defs>
      <linearGradient id='g'>
        <stop offset='0' stop-color='#fff' stop-opacity='0'/>
        <stop offset='1' stop-color='#fff' stop-opacity='1'/>
      </linearGradient>
      <mask id='m'>
        <rect x='0' y='0' width='1' height='1' fill='url(#g)'/>
      </mask>
      <linearGradient id='a' gradientTransform='rotate(90)'>
        <stop offset='0' stop-color='${colors.tr}'/>
        <stop offset='1' stop-color='${colors.br}'/>
      </linearGradient>
      <linearGradient id='b' gradientTransform='rotate(90)'>
        <stop offset='0' stop-color='${colors.bl}'/>
        <stop offset='1' stop-color='${colors.tl}'/>
      </linearGradient>
    </defs>

    <rect x='0' y='0' width='1' height='1' fill='url(#a)' mask='url(#m)'/>
    <rect x='0' y='0' width='1' height='1' fill='url(#b)' mask='url(#m)' transform='translate(1,1) rotate(180)'/> 
  </svg>`;

  // Clean up and encode the SVG
  let r = svgTemplate
    .replace(/> +/g, ">")
    .replace(/ +</g, "<")
    .replace(/>/g, "%3E")
    .replace(/</g, "%3C")
    .replace(/#/g, "%23")
    .replace(/"/g, "'")
    .replace(/ +/g, " ")
    .replace(/^ +/g, "")
    .split("\n")
    .join(" ");

  // Return the background-image css rule
  return `url("data:image/svg+xml;utf8,${r}");`;
}
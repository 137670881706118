//CcSite/src/views/PgLanding.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button, Center, Divider,
  Flex, Grid, GridItem,
  Heading,
  HStack, Image, Input, InputGroup, InputRightElement,
  Link,
  Spacer, Text, Textarea,
  useTheme, VStack,
} from '@chakra-ui/react';
import {useLocation, useNavigate} from 'react-router-dom';
import LogoHstackWhite from "assets/logos/logo-title-hstack-white.svg";
import AppFooter from './navs/AppFooter.js';
import { useAppStore } from "../services/useAppStore";
import BrandLogo from "assets/logos/logo-white.svg";
import BrandLogoBlack from "assets/logos/logo-black.svg";
import BrandLogoTitle from "assets/logos/logo-title-hstack-white.svg";
import MacFrame from "assets/img/MacFrame.png";
import MacDeskBg from "assets/img/Hero BG Image.webp";
import MacCcApp from "assets/img/CUBECERTSCREEN.jpg";
import UXMock1 from "assets/img/UXMock1.png";
import UXMock2 from "assets/img/UXMock2.png";
import UXMock3 from "assets/img/UXMock3.png";
import IconLogistics from "assets/img/logistics.svg";
import IphoneBgImg from "assets/img/Iphone BG Image.webp";
import BlueBlurBg from "assets/img/Blue Blur.webp";
import QrExampleSm from "assets/img/qr-example-small.png";
import QrExampleLg from "assets/img/qr-example-large.png";
import IconShield from "assets/img/shield.svg";
import OrangeBlurBg from "assets/img/Orange Blur.jpg";
import IconCheck from "assets/img/Empty Check.svg";
import IconCheckFilled from "assets/img/Filled Check.svg";
import AuthStory1 from "assets/img/auth-story-1.jpg";
import AuthStory2 from "assets/img/auth-story-2.jpg";
import AuthStory3 from "assets/img/auth-story-3.jpg";
import LogisticsStory1 from "assets/img/logistics-story-1.jpg";
import LogisticsStory2 from "assets/img/logistics-story-2.jpg";
import ProtectionStory1 from "assets/img/protection-story-1.jpg";
import ProtBg from "assets/img/protection-story-1-bg.jpg";
import ProtQr1 from "assets/img/prot-qr-1.png";
import ProtQr2 from "assets/img/prot-qr-2.png";

import {HFlex, HFlexCC, HFlexSC, S, VFlex, VFlexCC, VFlexCS, VFlexSC, VFlexSS} from "./bits/UtilityTags";
import {bgImgGrdPinkProps} from "../theme/global";
import {useScroll, motion, useTransform} from "framer-motion";
import ProductCard from "./bits/ProductCards";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import {ContactForm} from "./bits/ContactForm";
import WBIcon from "./bits/WBIcon";
import {H1, H2, H3} from "./bits/H";
import Mtn from "./bits/Mtn";
import PricingPlans from "./PricingPlans";
import {fira} from "../theme/foundations/fonts";
import ProductCards from "./bits/ProductCards";
import NavLink from "../components/NavLink";
import useNavigateAndScroll from "../helpers/navigationHelper";





const BgSection = ({children, ...rest}) => {
  return (
    <VFlexCC sx={{
      position: 'relative',
      zIndex: 0,
      '::before': {
        content: '""',
        position: 'absolute',
        width: '100vw',
        height: '100%',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: -1,
        backgroundColor: '#0a0a0a',
      },
    }} {...rest}>
      <>{children}</></VFlexCC>
  )
}

const PanelBadgeLabel = ({i, text, children, ...rest}) => {
  return (
    <HFlexSC gap={2}>
      <WBIcon i={i} alt="Panel Label Icon" size={6} p='2px' br={5}/>
      <Text color={'white'} fontSize={14} fontWeight={400}>{text}</Text>
    </HFlexSC>
  )
}

const PgLanding = () => {
  const appMainRef = useAppStore(s=>s.appMainRef);
  const { scrollY, scrollYProgress } = useScroll({container: appMainRef});
  const navigateAndScroll = useNavigateAndScroll();

  const scale = useTransform(scrollY, [0, 400], [0.7, 1], {
    clamp: true, // Ensures the output value does not go beyond the provided scale range [0.7, 1]
  });

  const liftUp = useTransform(scrollY, [0, 400], [0, -100], {
    clamp: true,
  });
  const handleNavToContact = (e) => {
    e.preventDefault();
    navigateAndScroll('/', 'contact');
  };

  const sxOffBlackBg = {
    position: 'relative',
    '::before': {
      content: '""',
      position: 'absolute',
      width: '100vw',
      height: '100%',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: -1,
      backgroundColor: '#0a0a0a',
    },
  }

  return (
    <VFlexCS id={'home'} alignSelf={'center'} w={['97%', '92%', '89%', '940px']}>
      {/* HERO SECTION */}

      <Mtn.VFlexCC
        style={{ y: liftUp }}
        variants={{
          hidden: {transition: {
              duration:0,
            }},
          show: {
            transition: {
              duration:0,
              staggerChildren: .1,
              staggerDirection: 1,
            }
          }
        }}
        initial="hidden"
        whileInView='show'
        viewport={{ once: false }}
        mt={120} mb={10} gap={6}>
        {/*TODO: sequentially animate these lines*/}

        <Mtn.FlipInY isChild transition={{ duration:.7 }}><WBIcon i={BrandLogoBlack} alt="Brand Logo Title" size={12} p={3} br={8}/></Mtn.FlipInY>

        <Mtn.FlipInX isChild transition={{ duration:.7 }}><H1>Discover <S color={'brand.purple'}>Cubecert</S></H1></Mtn.FlipInX>
        <Mtn.FlipInX isChild transition={{ duration:.7 }}><H1>Product</H1></Mtn.FlipInX>
        <Mtn.FlipInX isChild transition={{ duration:.7 }}><H1>Authentication</H1></Mtn.FlipInX>

        {/*<Mtn.FlipInX del={.6}><Text sx={{color:'gray', ...fira, fontSize:'18px',fontWeight:'400'}}>US Patent No. US11991284B1</Text></Mtn.FlipInX>*/}
        <Mtn.FlipInX isChild transition={{ duration:.7 }}>
          <Text w={['300px', '400px', '400px']} textAlign={'center'}>
            Introducing an innovative, patented,<br/> decentralized, product authentication solution.
            <br/>US Patent No. US11991284B1
          </Text>
        </Mtn.FlipInX>

        <Mtn.FlipInX isChild transition={{ duration:.7 }}>
          <Button variant="purple" onClick={handleNavToContact}>Request Demo</Button>
        </Mtn.FlipInX>
      </Mtn.VFlexCC>

      {/* MACBOOK GRAPHIC */}
      {/*TODO: fix: lighten macbook image*/}
      <VFlexCC w='100%' id='macimg'>
        {/*TODO: update mac desktop app image with new screenshot*/}

        <Flex sx={{
          bgImage: MacFrame, bgSize: 'contain', bgPos: 'center', bgRepeat: 'no-repeat', position: 'relative',
          w: '100%', h: 0, paddingTop: '56.25%'
        }}>
          <Box sx={{
            position: 'absolute', top: "6%", bottom: "8%", left: "11.6%", right: "11.5%", overflow: 'hidden',
            bgImage: MacDeskBg, bgSize: 'cover', bgPos: 'center', bgRepeat: 'no-repeat',
            bgColor: 'brand.purple'
          }}>
            <motion.img
              src={MacCcApp}
              sx={{position: 'absolute', width: '100%', height: '100%'}}
              alt="Macbook Cubecert App"
              initial={{scale: 0.7}}
              style={{scale}}
            />
          </Box>
        </Flex>
      </VFlexCC>
      {/* FEATURES SECTION */}
      {/*TODO: add space above this section*/}
      <VFlexCC id='section1' pt={100}>
        <VFlexCS w='100%' gap={15}>
          <HFlex justify={'center'}
                 flexDir={{ base: 'column', md: 'row'} }
                 align={{ base: 'center', md: 'center'}}
                 w={'100%'}
                 sx={{overflow: 'hidden', padding: [4, 4], gap: 4}}
          >
            <Mtn.FlipInX><H2 textAlign={{ base: 'center', md: 'right' }}>Unbreakable<br/>Authentication</H2></Mtn.FlipInX>
            <Mtn.FlipInX sx={''}><VFlexCS w={['316px', '356px']} gap={5}>
              {/*<Text>
                  Cubecert harnesses the power of blockchain, the most secure and immutable technology available.
                </Text>*/}
              <Text alignSelf={'center'} textAlign={['center','center','left']}>
                Our software creates a decentralized network where product information is stored and verified,
                eliminating the risk of tampering or manipulation.
              </Text>
            </VFlexCS></Mtn.FlipInX>
          </HFlex>
          <Mtn.Flex
            variants={{
              hidden: {transition: {
                duration:0,
              }},
              show: {
                transition: {
                  duration:0,
                  staggerChildren: .3,
                  staggerDirection: 1,
                }
              }
            }}
            initial="hidden"
            whileInView='show'
            viewport={{ once: false }}
            alignSelf='center' w={['65% ', 'auto', 'auto']} flexDirection={["column", "row"]} gap={15}
          >
            <Mtn.FadeScaleIn isChild><VFlexSS
              sx={{borderRadius: 20, bgImage: IphoneBgImg, bgSize: 'cover', bgRepeat: 'no-repeat', p: 26}}>
              <Image src={AuthStory1} alt="AuthStory1"/>
            </VFlexSS></Mtn.FadeScaleIn>
            <Mtn.FadeScaleIn isChild><VFlexSC
              sx={{borderRadius: 20, bgImage: IphoneBgImg, bgSize: 'cover', bgRepeat: 'no-repeat', p: 26}}>
              <Image src={AuthStory2} alt="AuthStory2"/>
            </VFlexSC></Mtn.FadeScaleIn>
            <Mtn.FadeScaleIn isChild ><VFlexSC
              sx={{borderRadius: 20, bgImage: IphoneBgImg, bgSize: 'cover', bgRepeat: 'no-repeat', p: 26}}>
              <Image src={AuthStory3} alt="AuthStory3"/>
            </VFlexSC></Mtn.FadeScaleIn>
          </Mtn.Flex>

        </VFlexCS>
      </VFlexCC>

      {/* TRACEABILITY SECTION */}
      <BgSection id='features' pt={24} mt={28}>
        <VFlexCC overflowX={'hidden'}>
          <Mtn.FlipInX><H1>Discover Transparent <br/>Traceability</H1></Mtn.FlipInX>
          <Mtn.FlipInX style={{display: 'flex', justifyContent: 'center'}}><Text textAlign='center' alignSelf={'center'}
                                                                                 mt={6} w={['79%', '65%', '65%', '78%']}>
            With Cubecert, transparency becomes the norm. Gain complete<br/> visibility into your
            supply chain and track the journey of your products from manufacturer to consumer.
          </Text></Mtn.FlipInX>
        </VFlexCC>

        {/* LOGISTICS SECTION */}
        <VFlexCC flexDir={["column", "column", "row-reverse"]} id='section3' pt={24} gap={2} w="100%" overflowX={'hidden'}>
            <VFlex alignSelf='center' justify='center' gap={7} p={25} w={['100%', '80%', '65%']}>
              <PanelBadgeLabel i={IconLogistics} text={'Logistics'}/>
              <H3>Simplify Chain<br/> of Logistics</H3>
              {/* TODO: make paragraph linebreaks with useBreakpoint so they only appear in lg and up */}
              <Text w={['100%', '440px', '440px']}>
                Using our Patented protocol, you can authenticate the origin, production details,
                distribution, and custodian history of each item with a simple scan of a QR code.
                Rest easy knowing that every step of the process is verifiable and trustworthy.
              </Text>
              <Button variant={'purple'} onClick={handleNavToContact}>Get Early Access</Button>
            </VFlex>
            <Mtn.VFlexCC
              variants={{
                hidden: {transition: {
                    duration:0,
                  }},
                show: {
                  transition: {
                    duration:0,
                  }
                }
              }}
              initial="hidden"
              whileInView='show'
              viewport={{ once: false }}
              alignSelf='center' w={['80%', '60%', '45%']} sx={{
              bgImage: BlueBlurBg, borderRadius: 20, bgSize: 'cover', bgRepeat: 'no-repeat', p: 4, gap: 3
            }}>
              <Mtn.InFromLeft isChild transition={{type:'ease',ease:'linear', duration:'0.5'}}>
                <Image src={LogisticsStory1} alt="Qr Example small" sx={{borderRadius: 14, ml: -10}}/>
              </Mtn.InFromLeft>
              <Mtn.InFromRight isChild transition={{type:'ease',ease:'linear', duration:'0.5'}}>
                <Image src={LogisticsStory2} alt="Qr Example large" sx={{borderRadius: 14,}}/>
              </Mtn.InFromRight>
            </Mtn.VFlexCC>
        </VFlexCC>

        <VFlexCC id='section4' pt={24} w="97%">
          <VFlexSS>
            <H2 textAlign='center'>
              Protecting Integrity,<br/>One Digital Certificate<br/>at a Time
            </H2>
            <Text textAlign='center' alignSelf={'center'} mt={6} w={['90%', '440px', '440px']}>
              Our tamper-proof QR codes protect the integrity of your products by providing a unique
              identifier for each item. By scanning the QR code, customers can instantly verify the
              authenticity and provenance of the product, building trust and confidence in your brand.
            </Text>
          </VFlexSS>
        </VFlexCC>

        {/* AUTHENTICITY SECTION */}
        <VFlexCC id='section5' py={24} w="100%">
          <VFlexCS flexDir={["column", "column", "row"]} justify={'center'} gap={2} w="100%" overflowX={'hidden'}>
            <VFlex alignSelf='center' justify='center' gap={7} p={25} w={['100%', '80%', '65%']}>
              <PanelBadgeLabel i={IconShield} text={'Authenticity'}/>
              <H3>Enhanced Brand Protection</H3>
              <Text w={['100%', '440px', '440px']}>
                Protect your brand's reputation and safeguard your customers' trust with Cubecert.
                By ensuring the authenticity of your products, you eliminate the risk of unauthorized
                counterfeits flooding the market.
              </Text>
              <Text w={['100%', '440px', '440px']}>
                Prevent revenue loss, maintain customer loyalty, and differentiate yourself from
                competitors by demonstrating your commitment to quality and authenticity
                using this patented digital certificate solution.
              </Text>
              <Button variant={'purple'} onClick={handleNavToContact}>Get Early Access</Button>

            </VFlex>
            <Mtn.VFlexCC
              sx={{
                w: ['80%', '60%', '45%'],
                h: "540px",
                position: 'relative',
                overflow: 'hidden',
                bgImage: ProtBg, bgSize: 'cover', bgRepeat: 'no-repeat',
                p: 4,
                gap: 3,
                borderRadius: 20,
              }}
              variants={{
                hidden: {transition: {
                    duration:0,
                  }},
                show: {
                  transition: {
                    duration:0,
                  }
                }
              }}
              initial="hidden"
              whileInView='show'
              viewport={{ once: false }}
            >

              <Mtn.InFromLeft isChild transition={{type:'ease',ease:'linear', duration:'0.5'}} style={{width: '100%', height: '100%', position: 'relative'}}><Image
                src={ProtQr1} alt="Qr Example small"
                sx={{
                  position: 'absolute', top: 3, left: 3,
                  height: 220, borderRadius: '14',
                }}
              /></Mtn.InFromLeft>
              <Mtn.InFromRight isChild transition={{type:'ease',ease:'linear', duration:'0.5'}} style={{width: '100%', height: '100%', position: 'relative'}}><Image
                src={ProtQr2} alt="Qr Example small"
                sx={{
                  position: 'absolute', bottom: 3, right: 3,
                  height: 220, borderRadius: '14',
                }}
              /></Mtn.InFromRight>
            </Mtn.VFlexCC>

          </VFlexCS>
        </VFlexCC>
      </BgSection>
      {/* PRODUCT AUTH SECTION */}
      <Mtn.FlipInX>
        <VFlexCC id='section6' pt={100}>
          <VFlex>
            <H1>
              Product Authentication<br/> Quick and Hassle-free
            </H1>
          </VFlex>

          <VFlex w={['90%', '85%', '490px']}>
            <Text textAlign='center' alignSelf={'center'} mt={6}>
              Consumers can easily scan the QR code and access comprehensive product information in seconds.
              Enjoy a seamless experience that combines advanced technology with simplicity.
            </Text>
          </VFlex>

        </VFlexCC>
      </Mtn.FlipInX>

      {/* PRODUCT CARDS SECTION */}
      <VFlexCC id='section7' pt={24} w="100%">
        <ProductCards/>
      </VFlexCC>


      {/* PRICING SECTION */}
      <VFlexCC id='plans' pt={24} mt={10}>
        <VFlex>
          <H1>Affordable Plans for Every <br/>Budget, Choose Yours</H1>
        </VFlex>
        <VFlex w={['85%', '55%', '55%', '48%']}>
          <Text textAlign='center' alignSelf={'center'} mt={6}>
            We offer a range of pricing plans to fit every budget and level of need.
            Whether you are a small business, or a large corporation, we have a plan that's right for you.
          </Text>
        </VFlex>
      </VFlexCC>

      {/* PRICING CARDS SECTION */}
      <PricingPlans id='section9' pt={16} sx={{overflowX: 'hidden', w: '100%', h:'100%' }}/>

      {/* FOR CONSUMERS SECTION */}
      <VFlexCC mt={10}>
        <VFlex>
          <H2>
            For Consumers
          </H2>
        </VFlex>
        <VFlex w={['85%', '55%', '55%', '48%']}>
          <Text textAlign='center' alignSelf={'center'} mt={6}>
            Consumers enjoy our service without cost or hassle. By creating an account, a user is able to authenticate
            their ownership of purchased products anonymously or with personal information of their choosing.
          </Text>
        </VFlex>
      </VFlexCC>


      {/* CONTACT HEADING SECTION */}
      <BgSection id='contact' pt={24} mt={24}>
        <VFlexCC>
          <H1 display={['block', 'none']}>See what <S color={'brand.purple'}>Cubecert</S><br/> can do for you</H1>
          <H1 display={['none','block']}>See what <S color={'brand.purple'}>Cubecert</S> can<br/> do for you</H1>
          <Text textAlign='center' alignSelf={'center'} mt={6} w={['79%', '65%', '45%', '58%']}>
            Please tell us about yourself and we will connect you with a Cubecert expert that
            can share more about the product and answer any quesitons you have.
          </Text>
        </VFlexCC>

        {/* CONTACT FORM SECTION */}
        <VFlexCC id='section11' py={24} maxW={'660px'} w={'100%'}>
          <ContactForm/>
        </VFlexCC>
      </BgSection>
      <AppFooter/>
    </VFlexCS>

  );
};

export default PgLanding;
import React from 'react';
import {
  browserName,
  browserVersion,
  isSafari,
  osVersion,
  isIOS,
  isIE,
} from 'react-device-detect';

const earliestSupportedVersions = [
  { name: 'Chrome', version: 52 },
  { name: 'Edge', version: 15 },
  { name: 'Safari', version: 12.1 },
  { name: 'Firefox', version: 55 },
  { name: 'Opera', version: 39 },
  { name: 'Samsung Internet', version: 5 },
  { name: 'Opera Mobile', version: 80 },
  { name: 'Android Browser', version: 126 },
  { name: 'KaiOS Browser', version: 3.1 },
];

const isBrowserUnsupported = () => {
  const isUnsupportedSafari = isSafari && isIOS && parseFloat(osVersion) < 12.2;
  const isIntersectionObserverSupported = 'IntersectionObserver' in window;

  const isUnsupportedBrowser = earliestSupportedVersions.some(
    (browser) =>
      browserName === browser.name && parseInt(browserVersion, 10) < browser.version
  );

  return (
    isUnsupportedSafari ||
    !isIntersectionObserverSupported ||
    isIE ||
    isUnsupportedBrowser
  );
};

const UnsupportedBrowserMessage = () => {
  return (
    <div style={{
      padding: '20px',
      backgroundColor: '#23184b',
      color: 'white',
      textAlign: 'center'
    }}
    >
      <p>The browser being used, or its version, is not supported. Please update your browser or use a different one.</p>
    </div>
  );
};

export { UnsupportedBrowserMessage, isBrowserUnsupported };

import {Box} from "@chakra-ui/react";
import React from "react";

const WBIcon = ({i, alt, size=10, p=2, br=7, bgColor='white', ...rest}) => {
  return (
    <Box p={p}
         borderRadius={br}
         boxSize={size}
         bgColor={bgColor}
         display="flex"
         alignItems="center"
         justifyContent="center"
         {...rest}
    >
      <Box boxSize={size}
           sx={{
             bgImage: i,
             bgSize: "contain",
             bgPos: "center",
             bgRepeat: "no-repeat"
           }}
      />
    </Box>
  );
};

export default WBIcon
import React, {Suspense, useEffect} from 'react';
import {Center, Spinner} from "@chakra-ui/react";
import {createBrowserRouter, Navigate, RouterProvider, redirect, useLocation} from 'react-router-dom';
import LayoutApp from './views/LayoutApp.js';
import AppNav from './views/navs/AppNav.js';
import PgLanding from './views/PgLanding.js';
import PgDocs from './views/PgDocs.js';
import PgNotFound from './views/PgNotFound.js';
// import { createStandaloneToast } from '@chakra-ui/toast'
// const { ToastContainer, toast } = createStandaloneToast()
// export {toast};
import PgAbout from "./views/PgAbout";
import PgPrivacy from "./views/PgPrivacy";
import PgTermsOfUse from "./views/PgTermsOfUse";
// const LazyPublicPage = React.lazy(() => import('./views/PublicPage'));

// const AppProvider = React.lazy(() =>
//   import(/* webpackChunkName: "views-app" */ './AppProvider.js')
// );

export const adminRoutes = [
  '/dash', '/settings', '/subscriptions', '/wallet-history',
]
export const publicRoutes = [
  '/', '/login', '/docs', '/404'
]
export const publicHandleRoute = [
  '/sf',
]

const router = createBrowserRouter([
  {
    element: <AppNav />,
    children: [
      { path: "/", element: <PgLanding />, },
      { path: "/404", element: <PgNotFound />, },
      { path: "/docs", element: <PgDocs />, },
      { path: "/about", element: <PgAbout />, },
      { path: "/privacy", element: <PgPrivacy />, },
      { path: "/terms-of-use", element: <PgTermsOfUse />, },
    ],
  },
  {
    path: "*", element: <PgNotFound />,
  },
]);

export default function App(props) {
  const { variant, children, ...rest } = props;

/*
  const { scrollY, scrollYProgress } = useScroll()
  // const isWindowScrolled = useAppStore(s=>s.isWindowScrolled)
  function onScrollChange(value){
    if(value>5 && !appState().isWindowScrolled){
      // console.log('win scrolled true')
      appState().set_isWindowScrolled(true)
    }
    else if(value<=5 && appState().isWindowScrolled){
      // console.log('win scrolled false')
      appState().set_isWindowScrolled(false)
    }
  }
  useEffect(() => {
    scrollY.on('change', onScrollChange);
  }, []);
*/

  return (<>
    <Suspense fallback={<div className="loading"/>}>
      <RouterProvider router={router}/>
      {/*<ToastContainer/>*/}
    </Suspense>
  </>);
}

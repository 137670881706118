import {isValidMotionProp, motion} from "framer-motion";
import React from "react";
import {chakra} from "@chakra-ui/react";

export const MotionBox = motion(chakra.div);
export const MotionFlex = motion(chakra.div);

MotionFlex.defaultProps = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center"
};

const fromTo = {
  FadeIn: [{opacity: 0},{opacity: 1}],
  FadeScaleIn: [{opacity: 0, scale: 0.7},{opacity: 1, scale: 1}],
  FlipInX: [{opacity: 0, rotateX: 90},{opacity: 1, rotateX: 0}],
  FlipInY: [{opacity: 0, rotateY: 90},{opacity: 1, rotateY: 0}],
  InFromLeft: [{opacity: 0, x: -100},{opacity: 1, x: 0}],
  InFromRight: [{opacity: 0, x: 100},{opacity: 1, x: 0}],
  InFromAbove: [{opacity: 0, y: -100},{opacity: 1, y: 0}],
  InFromBelow: [{opacity: 0, y: 100},{opacity: 1, y: 0}],
}
/**
 * A library of React components utilized to create different animations
 * using the Framer Motion library.
 * Each component in the library is a Higher Order Component (HOC) that accepts a child prop,
 * and provides the child with an animation behavior. Each component also accepts some optional
 * parameters including duration (dur) and delay (del) to further customize the animation
 *
 * @namespace Mtn
 *
 * @param {ReactNode} children - The elements to animate.
 * @param {number} [dur] - The duration of the animation, in seconds. Defaults to 0.5s if not given.
 * @param {number} [del] - The delay before the animation starts, in seconds. Defaults to 0s if not given.
 * @param {Object} rest - Any additional props will be passed directly to the motion component.
 *
 * @returns {ReactElement} - A React element (motion.div) with attached animation behavior.
 */
export const Mtn = {
  Flex: MotionFlex,

  Box: MotionBox,

  VFlex: props => <MotionFlex flexDirection="column" alignItems='flex-start' justifyContent="flex-start" {...props} />,
  VFlexCC: props => <MotionFlex flexDirection="column" alignItems="center" justify='center' {...props} />,
  VFlexCS: props => <MotionFlex flexDirection="column" alignItems="center" justifyContent='stretch' {...props} />,
  VFlexSC: props => <MotionFlex flexDirection="column" alignItems="stretch" justifyContent='center' {...props} />,
  VFlexSS: props => <MotionFlex flexDirection="column" alignItems="stretch" justifyContent='stretch' {...props} />,

  HFlex: props => <MotionFlex flexDirection="row" justifyContent="flex-start" alignItems='flex-start' {...props} />,
  HFlexCC: props => <MotionFlex flexDirection="row" justifyContent="center" alignItems='center' {...props} />,
  HFlexCS: props => <MotionFlex flexDirection="row" justifyContent="center" alignItems='stretch' {...props} />,
  HFlexSC: props => <MotionFlex flexDirection="row" justifyContent="stretch" alignItems='center' {...props} />,
  HFlexSS: props => <MotionFlex flexDirection="row" justifyContent="stretch" alignItems='stretch' {...props} />,

  FadeIn: ({ children, dur = 0.5, del = 0, isChild = false, ...rest }) => (
    <MotionBox
      {...(!isChild ? {
        initial: "hidden",
        whileInView: "show",
        viewport: { once: false },
        variants: {
          hidden: { ...fromTo.FadeIn[0], transition: { duration: 0, delay: 0 } },
          show: { ...fromTo.FadeIn[1], transition: { duration: dur, delay: del } },
        }
      } : {
        variants: {
          hidden: fromTo.FadeIn[0],
          show: fromTo.FadeIn[1],
        }
      })}
      {...rest}
    >
      {children}
    </MotionBox>
  ),
  FadeScaleIn: ({ children, dur = 0.5, del = 0, isChild = false, ...rest }) => (
    <MotionBox
      {...(!isChild ? {
        initial: "hidden",
        whileInView: "show",
        viewport: { once: false },
        variants: {
          hidden: { ...fromTo.FadeScaleIn[0], transition: { duration: 0, delay: 0 } },
          show: { ...fromTo.FadeScaleIn[1], transition: { duration: dur, delay: del } },
        }
      } : {
        variants: {
          hidden: fromTo.FadeScaleIn[0],
          show: fromTo.FadeScaleIn[1],
        }
      })}
      {...rest}
    >
      {children}
    </MotionBox>
  ),
  FlipInX: ({ children, dur = 0.5, del = 0, isChild = false, ...rest }) => (
    <MotionBox
      {...(!isChild ? {
        initial: "hidden",
        whileInView: "show",
        viewport: { once: false },
        variants: {
          hidden: { ...fromTo.FlipInX[0], transition: { duration: 0, delay: 0 } },
          show: { ...fromTo.FlipInX[1], transition: { duration: dur, delay: del } },
        }
      } : {
        variants: {
          hidden: fromTo.FlipInX[0],
          show: fromTo.FlipInX[1],
        }
      })}
      {...rest}
    >
      {children}
    </MotionBox>
  ),
  FlipInY: ({ children, dur = 0.5, del = 0, isChild = false, ...rest }) => (
    <MotionBox
      {...(!isChild ? {
        initial: "hidden",
        whileInView: "show",
        viewport: { once: false },
        variants: {
          hidden: { ...fromTo.FlipInY[0], transition: { duration: 0, delay: 0 } },
          show: { ...fromTo.FlipInY[1], transition: { duration: dur, delay: del } },
        }
      } : {
        variants: {
          hidden: fromTo.FlipInY[0],
          show: fromTo.FlipInY[1],
        }
      })}
      {...rest}
    >
      {children}
    </MotionBox>
  ),
  InFromLeft: ({ children, dur = 0.5, del = 0, isChild = false, ...rest }) => (
    <MotionBox
      {...(!isChild ? {
        initial: "hidden",
        whileInView: "show",
        viewport: { once: false },
        variants: {
          hidden: { ...fromTo.InFromLeft[0], transition: { duration: 0, delay: 0 } },
          show: { ...fromTo.InFromLeft[1], transition: { duration: dur, delay: del } },
        }
      } : {
        variants: {
          hidden: fromTo.InFromLeft[0],
          show: fromTo.InFromLeft[1],
        }
      })}
      {...rest}
    >
      {children}
    </MotionBox>
  ),
  InFromRight: ({ children, dur = 0.5, del = 0, isChild = false, ...rest }) => (
    <MotionBox
      {...(!isChild ? {
        initial: "hidden",
        whileInView: "show",
        viewport: { once: false },
        variants: {
          hidden: { ...fromTo.InFromRight[0], transition: { duration: 0, delay: 0 } },
          show: { ...fromTo.InFromRight[1], transition: { duration: dur, delay: del } },
        }
      } : {
        variants: {
          hidden: fromTo.InFromRight[0],
          show: fromTo.InFromRight[1],
        }
      })}
      {...rest}
    >
      {children}
    </MotionBox>
  ),
  InFromAbove: ({ children, dur = 0.5, del = 0, isChild = false, ...rest }) => (
    <MotionBox
      {...(!isChild ? {
        initial: "hidden",
        whileInView: "show",
        viewport: { once: false },
        variants: {
          hidden: { ...fromTo.InFromAbove[0], transition: { duration: 0, delay: 0 } },
          show: { ...fromTo.InFromAbove[1], transition: { duration: dur, delay: del } },
        }
      } : {
        variants: {
          hidden: fromTo.InFromAbove[0],
          show: fromTo.InFromAbove[1],
        }
      })}
      {...rest}
    >
      {children}
    </MotionBox>
  ),
  InFromBelow: ({ children, dur = 0.5, del = 0, isChild = false, ...rest }) => (
    <MotionBox
      {...(!isChild ? {
        initial: "hidden",
        whileInView: "show",
        viewport: { once: false },
        variants: {
          hidden: { ...fromTo.InFromBelow[0], transition: { duration: 0, delay: 0 } },
          show: { ...fromTo.InFromBelow[1], transition: { duration: dur, delay: del } },
        }
      } : {
        variants: {
          hidden: fromTo.InFromBelow[0],
          show: fromTo.InFromBelow[1],
        }
      })}
      {...rest}
    >
      {children}
    </MotionBox>
  ),

};

export default Mtn
import {Heading} from "@chakra-ui/react";
import React from "react";

export const H1 = ({children, ...rest}) => {
  return (
    <Heading as={'h1'} size={['2xl','2xl','3xl']} textAlign='center' lineHeight={[1.1, 1.2, 1.3]} {...rest}>
      {children}
    </Heading>
  )
}
export const H2 = ({children, ...rest}) => {
  return (
    <Heading as={'h2'} size={['lg','xl']} lineHeight={1.1} {...rest}>
      {children}
    </Heading>
  )
}
export const H3 = ({children, ...rest}) => {
  return (
    <Heading as='h3' size={['lg','xl']} {...rest}>
      {children}
    </Heading>
  )
}
import {Box, Button, Divider, Grid, GridItem, Heading, Image, Text} from "@chakra-ui/react";
import React from "react";
import {HFlexSC, vflex, VFlex, VFlexCC} from "./bits/UtilityTags";
import IconCheck from "../assets/img/Empty Check.svg";
import IconCheckFilled from "../assets/img/Filled Check.svg";
import NavLink from "components/NavLink";
import Mtn from "./bits/Mtn";
import { motion} from "framer-motion";
import useNavigateAndScroll from "../helpers/navigationHelper";


const PriceCardBadge = ({text, ...rest}) => {
  return (
    <Box as="span"
         sx={{ display: 'inline-flex', borderRadius: 50, fontSize: 15, border: '1px solid', borderColor: '#333', px: 3, pb:'8px', pt:'7px' }}
         lineHeight="1"
         alignItems="center"
         justifyContent="center"
         fontFamily={'plex'}
    >
      {text}
    </Box>
  )
};
const PriceCardHeading = ({text, ...rest}) => {
  return (
    <Heading as='h4' fontWeight={600} size='xl' textAlign='center'>{text}</Heading>
  )
};

const PriceCardItem = ({type, text, ...rest}) => {
  const Icon = type==='basic'? IconCheck : IconCheckFilled;
  return (
    <Mtn.FadeScaleIn isChild style={{display:'inline-flex', alignItems:'center', gap:'12px'}}>
      <Image boxSize={type==='basic'?3:4} src={Icon}/>
      <Text sx={{fontSize:'16', color:'white'}}>{text}</Text>
    </Mtn.FadeScaleIn>
  )
};

export const PricingPlans = ({children, ...rest}) => {
  const navigateAndScroll = useNavigateAndScroll();

  const handleNavToContact = (e) => {
    e.preventDefault();
    navigateAndScroll('/', 'contact');
  };

  return (
      <VFlexCC {...rest}>
        <Grid id='gridgrid' gap={3} templateColumns={{ base: '1fr', md: "5fr 6fr" }} h="100%">
          <Mtn.InFromLeft transition={{
            duration: 1,
            delayChildren: .1,
            staggerChildren: .1,
            staggerDirection: 1
          }}><GridItem>
            {/* PROFESSIONAL PLAN CARD */}
            <VFlex gap={5} alignItems="start" sx={{
              minHeight: '500px', borderRadius: 35, border: '1px solid #333', p: 5, bgColor: '#1b1c1d', overflow:'hidden'
            }}>
              <PriceCardBadge text='BASIC'/>
              <PriceCardHeading text='Professional Plan'/>
              <Divider/>
              <Mtn.VFlex
                variants={{
                  hidden: {opacity: 0},
                  show: {
                    opacity: 1,
                    transition: {
                      duration: 1,
                      delayChildren: .1,
                      staggerChildren: .1,
                      staggerDirection: 1
                    }
                  }
                }}
                initial="hidden"
                whileInView='show'
                viewport={{once: false}} sx={{display: 'flex', gap: 3, flexGrow: 1, flexDirection: 'column'}}>
                  <PriceCardItem type='basic' text='Single User'/>
                  <PriceCardItem type='basic' text='Limited Features'/>
                  <PriceCardItem type='basic' text='Limited Reporting'/>
                  <PriceCardItem type='basic' text='Limited Transactions'/>
                  <PriceCardItem type='basic' text='Basic Support'/>
                  <PriceCardItem type='basic' text='Limited Custodians'/>

              </Mtn.VFlex>
              <Mtn.InFromBelow
                isChild transition={{type:'tween',ease:'linear', duration:.5}}
                sx={{...vflex, gap:3, w:'100%' , color:'#333'}}
              >
                <Divider borderColor={'#333'}/>
                <HFlexSC gap={1} w='100%' justifyContent={'space-between'}>
                  <Text>Single User License Per Year</Text>
                  <Button variant={'purple'} onClick={handleNavToContact}>Inquire</Button>
                </HFlexSC>
              </Mtn.InFromBelow>

            </VFlex>
          </GridItem></Mtn.InFromLeft>

          <Mtn.InFromRight transition={{
            duration: 1,
            delayChildren: .1,
            staggerChildren: .1,
            staggerDirection: 1
          }}><GridItem>
            {/* ULTIMATE PLAN CARD */}
            <VFlex gap={5} alignItems="start" sx={{
              minHeight: '500px', borderRadius: 35, border: '1px solid #333', p: 5, bgColor: '#0d0d0d', overflow:'hidden'
            }}>
              <PriceCardBadge text='ULTRA'/>
              <PriceCardHeading text='Ultimate Plan'/>

              <Divider/>
              <Mtn.VFlex
                variants={{
                  hidden: {opacity: 0},
                  show: {
                    opacity: 1,
                    transition: {
                      duration: 1,
                      delayChildren: .1,
                      staggerChildren: .1,
                      staggerDirection: 1
                    }
                  }
                }}
                initial="hidden"
                whileInView='show'
                viewport={{once: false}} sx={{display: 'flex', gap: 3, flexGrow: 1, flexDirection: 'column'}}>
              <Grid gap={1} templateColumns={{base: '1fr', sm: "1fr 1fr"}} flexGrow={1}>
                <VFlex gap={3}>
                  <PriceCardItem type='ultra' text='Multiple Users'/>
                  <PriceCardItem type='ultra' text='Expanded Features'/>
                  <PriceCardItem type='ultra' text='Robust Reporting'/>
                  <PriceCardItem type='ultra' text='Proactive Monitoring'/>
                  <PriceCardItem type='ultra' text='Premium Support'/>
                </VFlex>
                <VFlex gap={3}>
                  <PriceCardItem type='ultra' text='Configuration Services'/>
                  <PriceCardItem type='ultra' text='Real-Time Collaboration'/>
                  <PriceCardItem type='ultra' text='Customization Options'/>
                  <PriceCardItem type='ultra' text='Unlimited Custodians'/>
                </VFlex>
              </Grid>
              </Mtn.VFlex>
              <Mtn.InFromBelow
                isChild transition={{type:'tween',ease:'linear', duration:.5}}
                sx={{...vflex, gap:3, w:'100%' , color:'#333'}}
              >
                <Divider borderColor={'#333'}/>
                <HFlexSC w='100%' justifyContent={'space-between'}>
                  <Text>Multiple User Licenses Per Year</Text>
                  <Button variant={'purple'} onClick={handleNavToContact}>Inquire</Button>

                </HFlexSC>
              </Mtn.InFromBelow>

            </VFlex>
          </GridItem>
          </Mtn.InFromRight>
        </Grid>
      </VFlexCC>

  )
}
export default PricingPlans
// src/helpers/navigationHelper.js
import { useNavigate } from 'react-router-dom';
import { useAppStore } from '../services/useAppStore';

const useNavigateAndScroll = () => {
  const navigate = useNavigate();
  const appMainRef = useAppStore(state => state.appMainRef);

  const navigateAndScroll = (to, elementId) => {
    navigate(to);
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element && appMainRef.current) {
        appMainRef.current.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
      }
    }, 100); // Small delay to ensure the navigation has completed
  };

  return navigateAndScroll;
};

export default useNavigateAndScroll;
